import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import PfIcon from '../assets/icons/PfIcon';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';

function AndrewHome() {
    return (
        <Container className='container'>
        <Box className='andrewBg'>
            <Box className='bannerLight'>
              <h3> People Arena <Link className='linkPop' to='../about-yann-fingers-bonato'><PfIcon /></Link></h3>
            </Box>
            <Box className='middle'>
                <p className='contentW personalContent'>
                <br />
                <a href='https://www.facebook.com/peoplearena' rel="noreferrer" target='_blank' className='noDeco'>
                <FacebookIcon />    Facebook
                </a>
                <br />
                <a href= 'https://www.instagram.com/peoplearena/' rel="noreferrer" target='_blank' className='noDeco'>
                <InstagramIcon />   Instagram
                </a>
                <br />
                <a href='https://linktr.ee/PeopleArena' rel="noreferrer" target='_blank' className='noDeco'>
                <MusicVideoIcon />    People Arena
                </a>
                <br />
                </p>
            </Box>
        </Box>
        </Container>
    )
}

export default AndrewHome;
