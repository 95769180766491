import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppleIcon from '@mui/icons-material/Apple';
import { useTranslation } from 'react-i18next';
import SpotifyIcon from '../assets/icons/SpotifyIcon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AmazonMusicIcon from '../assets/icons/AmazonMusicIcon';
import YouTubeMusicIcon from '../assets/icons/YouTubeMusicIcon';
import { Typography } from '@mui/material';

const Music = () => {
  const { t } = useTranslation();

  return (
      <Box className='banner'>
        <h1>Poussé {t('music.available_now')}</h1>
        <Box className='bannerMusic'>
          <img className='albumCover' src="https://pushforward.online/static/media/images/Push_Forward_Pousse_Album_Cover_400.png" alt='Poussé Album Cover' />
        </Box>

        <Button className='streamMusicButton' href='https://music.apple.com/us/album/pouss%C3%A9/1777566553' target='_blank' variant='contained' startIcon={<AppleIcon />}>
          Music
        </Button>
        <Button className='streamMusicButton' href='https://open.spotify.com/album/2ge6ZrABuxbjHVkVTJm0Nw?si=VVsm1l_gR8yBB7GW0K9wOQ' target='_blank' variant='contained' startIcon={<SpotifyIcon />}>
          Spotify
        </Button>
        <Button className='streamMusicButton' href='https://music.youtube.com/playlist?list=OLAK5uy_lXY01JlPrJqisKWA9QJOfIvZ_hkz8hBYU&si=8rMNkZz7eViagM2D'target='_blank' variant='contained' startIcon={<YouTubeMusicIcon />}>
          YouTube
        </Button>
        <Button className='streamMusicButton' href='https://www.youtube.com/playlist?list=PLkZQOXj8KXiJ6xVpngSR1j4m14Ek-CtSo' target='_blank' variant='contained' startIcon={<YouTubeIcon />}>
          YouTube
        </Button>
        <Button className='streamMusicButton' href='https://amazon.ca/music/player/albums/B0DLF7LD2F?marketplaceId=A2EUQ1WTGCTBG2&musicTerritory=CA&ref=dm_sh_8TIxQr2Kb2HjtxmvGRguzbr1C' target='_blank' variant='contained' startIcon={<AmazonMusicIcon />}>
          Amazon
        </Button>
        <Typography>{t('music.also_available_on_favorite_service')}</Typography>
      </Box>
  );
};

export default Music;
