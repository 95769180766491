import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PfIcon from './assets/icons/PfIcon';
import i18n from './i18n';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}


function NavBar() {
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState('fr');
  const handleLanguageChange = () => {
    if (language === 'en') {
      setLanguage('fr');
    } else {
      setLanguage('en');
    }
    i18n.changeLanguage(language);
  }
  return (      
    <HideOnScroll>
      <AppBar>
        <Toolbar className='nav'>
          <Link className='noDeco' to='/'>
            <Typography variant='h6' component='div' className='nav navItems'>
                Push Forward <PfIcon className='navIcon'/>
            </Typography>
          </Link>
          <Link className='noDeco' to='/store'>
            <Typography variant='h6' component='div' className='nav navItems'>
                {t('nav.merch_store')} <PfIcon className='navIcon'/>
            </Typography>
          </Link>
          <Link className='noDeco' to='/music'>
            <Typography variant='h6' component='div' className='nav navItems'>
              {t('nav.music')} <PfIcon className='navIcon' />
            </Typography>
          </Link>
          <span onClick={handleLanguageChange} className='nav navItems navLeft'>{t('nav.language')}</span>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}

export default NavBar;
