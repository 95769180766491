import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import PfIcon from '../assets/icons/PfIcon';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';

function YannHome() {
    return (
        <Container className='container'>
        <Box className='yannBg'>
            <Box className='bannerLight'>
              <h3> Yann "Fingers" Bonato <Link className='linkPop' to='../about-rim-bam-bito'><PfIcon /></Link></h3>
            </Box>
            <Box className='signatureContainer'>
                <img className='signature' alt='fingers-bonato-signature' src='/fingers-white.png' />
            </Box>
            <Box>
                <p className='contentW personalContent'>
                <br />
                <a href='https://www.facebook.com/yann.bonato.deangelis' rel="noreferrer" target='_blank' className='noDeco'>
                <FacebookIcon />   Facebook
                </a>
                <br />
                <a href= 'https://www.instagram.com/yanimal1981/' rel="noreferrer" target='_blank' className='noDeco'>
                <InstagramIcon />     Instagram
                </a>
                <br />
                <a href='https://www.facebook.com/senzafineband/' rel="noreferrer" target='_blank' className='noDeco'>
                <MusicVideoIcon />    Senzafine
                </a>
                <br />
                </p>
            </Box>
        </Box>
        </Container>
    )
}

export default YannHome;
