import { SvgIcon } from '@mui/material';
import * as React from 'react';

function PfIconRev(props) {
    return (
        <SvgIcon { ...props}>
            <path transform='scale(-1, -1) translate(-24, -24)' d="M12.51,10.49l-0.26-0.17L2.01,5.05H1.63L1.32,5.53l0.31,11.93h0.26l10.5-6.3l0.21-0.14V10.7L12.51,10.49z M2.39,6.21
            l4.2,2.11l-4.2,2.49V6.21z M2.49,15.91l-0.1-4.04l5.16-3.06l3.73,1.87L2.49,15.91z"/>
            <path transform='scale(-1, -1) translate(-24, -24)' d="M22.77,10.73l-7.98-4.7l-0.57-0.24h-0.24l-0.16,0.22L13.67,6.4l0.22,10.35l0.31,0.1l8.7-5.72v-0.16L22.77,10.73z
            M14.72,15.46l-0.09-4.58l3.83,2.09L14.72,15.46z M19.42,12.44L14.7,9.85L14.64,6.9l6.89,4.06L19.42,12.44z"/>
        </SvgIcon>
    )
}

export default PfIconRev;
