import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PfIcon from './assets/icons/PfIcon';
import { Link } from 'react-router-dom';

function About() {
    return (
        <Container className='container'>
            <Box className='bgimg3alt'>
                <p className='contentW'>
            Based out of Montreal, Quebec in Canada, The Push Forward sound was born from spontaneous jam sessions that first made their appearance, before, after and in between practice sessions for another project. That other project was People Arena. 
<br /><br />
The group was rehearsing a set of music from the Ticket to Your Funeral collection. A show was planned for May 8th 2020, but ultimately ended up being cancelled due to the covid-19 pandemic. The group at the time, made up of Degenotron,<Link to='/about-yann-fingers-bonato' className='linkPop'> Yann "Fingers" Bonato</Link>, <Link to='/about-rim-bam-bito' className='linkPop'>Rim "Bam" Bito</Link> and <Link to='/about-people-arena' className='linkPop'>People Arena</Link>, felt they could Push Forward with the musical adventure they had embarked on for over 2 years by that point, breaking away from the People Arena tracks and looking to refine the idea rich jams they had accumulated over that period of time.
<br /><br />
The resulting body of work is slated to appear on the band's debut album, early in 2024. <PfIcon />
                </p>
            </Box>
        </Container>
    )
}

export default About;
