import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SpotifyIcon from './assets/icons/SpotifyIcon';
import AppleIcon from '@mui/icons-material/Apple';

function Footer() {
  return (
    <Box>
      <Toolbar className='footer'>
        <Link href='https://www.instagram.com/pushforward.online' target='_blank' className='noDeco'>
          <InstagramIcon className='noDeco' />
        </Link>
        <Link href='https://www.facebook.com/profile.php?id=61558005543750' target='_blank' className='noDeco'>
          <FacebookIcon className='noDeco' />
        </Link>
        <Link href='https://www.youtube.com/channel/UCJrljr8O4kkTqWDBmElCPCw' target='_blank' className='noDeco'>
          <YouTubeIcon className='noDeco' />
        </Link>
        <Link href='https://open.spotify.com/artist/4WQRHx9X2yrzDSTkbKUvTE?si=edPFNsoPQwmAmXmvyW1QqA' target='_blank' className='noDeco'>
          <SpotifyIcon className='noDeco' />
        </Link>
        <Link href='https://music.apple.com/us/artist/push-forward/1746204663' target='_blank' className='noDeco'>
          <AppleIcon className='noDeco' />
        </Link>
      </Toolbar>
    </Box>
  );
}

export default Footer;
