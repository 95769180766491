import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';
import Store from './Store';
import Home from './Home';
import './App.css';
import About from './About';
import Music from './Music/Music';
import YannHome from './memberPages/YannHome';
import CarloHome from './memberPages/CarloHome';
import AndrewHome from './memberPages/AndrewHome';

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='store' element={<Store />} />
          <Route path='about' element={<About />} />
          <Route path='music' element={<Music />} />
          <Route path='about-yann-fingers-bonato' element={<YannHome />} />
          <Route path='about-rim-bam-bito' element={<CarloHome />} />
          <Route path='about-people-arena' element={<AndrewHome />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
