import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper'
import PushForwardTee from './Store/PushForwardTee.png';
import PeopleArenaTeeW from './Store/PeopleArenaTeeW.png';
import PeopleArenaTeeB from './Store/PeopleArenaTeeB.png';

function Store() {
 const { t } = useTranslation();
  const [openA, setOpenA] = React.useState(false);
  const [openB, setOpenB] = React.useState(false);
  const [openC, setOpenC] = React.useState(false);
  const handleCloseA = () => {
      setOpenA(false);
  };
  const handleOpenA = () => {
      setOpenA(true);
  };
  const handleCloseB = () => {
    setOpenB(false);
};
const handleOpenB = () => {
    setOpenB(true);
};
const handleCloseC = () => {
  setOpenC(false);
};
const handleOpenC = () => {
  setOpenC(true);
};
  return (
    <Container disableGutters={true} maxWidth={false} className='Container'>
      <Box className='banner'>
        <h3>{t('store.merch_at_venue')}</h3>
      </Box>
      <Grid container justifyContent='space-evenly' rowSpacing={2}>
       <Grid item xs={12} sm={6} md={3} key='pft'>
        <Card sx={{ minWidth: 275,}}>
            <CardMedia
                component='img'
                image={PushForwardTee}
                alt={t('store.pf_tee')}
                onClick={handleOpenA}
            />
            <CardActions>
                <Button color='warning' size='small' onClick={handleOpenA}>
                {t('store.pf_tee')}
                </Button>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openA}
                    onClick={handleCloseA}
                >
                    <Paper>
                        <img className='autoScaleImage' src={require("./Store/PushForwardTee.png")} alt={t('store.pf_tee')} />
                    </Paper>
                </Backdrop>
            </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} key='patw'>
      <Card sx={{ minWidth: 275 }}>
            <CardMedia
                component='img'
                image={PeopleArenaTeeW}
                alt={t('store.pa_white_tee')}
                onClick={handleOpenB}
            />
            <CardActions>
                <Button color='warning' size='small' onClick={handleOpenB}>
                {t('store.pa_white_tee')}
                </Button>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openB}
                    onClick={handleCloseB}
                >
                    <Paper>
                        <img className='autoScaleImage' src={require("./Store/PeopleArenaTeeW.png")} alt={t('store.pa_white_tee')} />
                    </Paper>
                </Backdrop>
            </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} key='patb'>
      <Card sx={{ minWidth: 275 }}>
            <CardMedia
                component='img'
                image={PeopleArenaTeeB}
                alt={t('store.pa_black_tee')}
                onClick={handleOpenC}
            />
            <CardActions>
                <Button color='warning' size='small' onClick={handleOpenC}>
                {t('store.pa_black_tee')}
                </Button>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openC}
                    onClick={handleCloseC}
                >
                    <Paper>
                        <img className='autoScaleImage' src={require("./Store/PeopleArenaTeeB.png")} alt={t('store.pa_black_tee')}/>
                    </Paper>
                </Backdrop>
            </CardActions>
        </Card>
      </Grid>
      </Grid>
    </Container>
  );
}

export default Store;
