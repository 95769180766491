import React from "react";
import {Outlet} from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Footer from './Footer';
import NavBar from './NavBar';

function Layout() {
  return (
    <>
      <NavBar />
      <Toolbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
